import { DatePicker, Input, Select } from "antd";
import { states } from "./listOfStates";
import MaskedInput from "antd-mask-input";

type FormFieldProps = {
  name: string;
  placeholder?: string;
};

export const getFormField = ({ name, placeholder }: FormFieldProps) => {
  const { Option } = Select;

  switch (name) {
    case "birth_date":
      return (
        <DatePicker
          className="ant-input"
          format="MM-DD-YYYY"
          placeholder="MM-DD-YYYY"
        />
      );

    case "state":
      return (
        <Select className="ant-input" bordered={false}>
          {states.map((state) => (
            <Option key={state} value={state}>
              {state}
            </Option>
          ))}
        </Select>
      );
      case "phoneNumber":
      return (
            <MaskedInput mask="111-111-1111" placeholder='000-000-0000' />
      );
    default:
      return (
        <Input placeholder={placeholder} className="custom-input-autofill" />
      );
  }
};
