import classNames from "classnames";

import phones from "assets/images/phones.png";
import "./style.scss";

type PhonesProps = {
  classname?: string;
};

const Phones = ({ classname }: PhonesProps) => {
  return (
    <div className={classNames(["phones-wrapper", classname])}>
      <img src={phones} alt="Native Apps" />
    </div>
  );
};

export default Phones;
