import { useState } from "react";

import { Row } from "antd";

import Phones from "components/Phones";
import Layout, { LayoutContentContainer } from "layout";
import MobileBanner from "components/MobileAppBanner";
import Banner from "components/Banner";
import RegistrationForm from "components/RegistrationForm";
import "./style.scss";
import Spacer from "components/Spacer";
import {env} from "utils/env";

const tocLink = env.REACT_APP_TERMS_AND_CONDITIONS_URL;
const privacyPolicyLink =  env.REACT_APP_PRIVACY_POLICY_URL;

const RegistrationPage = () => {
  const [registrationModalIsOpen, setRegistrationFormVisible] = useState(false);

  return (
    <Layout>
      <Banner setRegistrationFormVisible={setRegistrationFormVisible} />
      <LayoutContentContainer>
        <div className="welcome-container-wrapper">
          <div className="description">
            <h2 className="welcome-container--heading">TrialCard’s Digital Hub and Engagement Platform</h2>
            <p className="welcome-container--description">
              PatientLink by TrialCard is a leading digital communication and
              engagement platform that integrates manufacturer-sponsored
              access and affordability programs with leading health and
              wellness destinations. Our programs offer flexibility and choice for
              whole person health that meets the needs of today’s demanding
              health consumer.
            </p>
          </div>
          <Phones />
        </div>
        <Row gutter={12}>
          <RegistrationForm
            registrationModalIsOpen={registrationModalIsOpen}
            setRegistrationFormVisible={setRegistrationFormVisible}
            programType="HB"
          />
        </Row>
        <MobileBanner />
        <Row className="links-wrapper">
          <a target="_blank" href={tocLink} rel="noreferrer">Terms and Conditions</a>
          <Spacer width={16} />
          <a target="_blank" href={privacyPolicyLink} rel="noreferrer">Privacy Policy</a>
        </Row>
        <Spacer height={48} />
      </LayoutContentContainer>
    </Layout>
  );
};

export default RegistrationPage;
