type ScaperProps = {
  width?: number;
  height?: number;
  type?: "inline-flex" | "flex";
  className?: string;
};

const Spacer = ({
  width = 0,
  height = 0,
  type = "inline-flex",
  className,
}: ScaperProps) => {
  return <div className={className} style={{ width, height, display: type }} />;
};

export default Spacer;
