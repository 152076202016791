import { isValidPhoneNumber } from "utils/validators";

export const formFields = [
  {
    name: "firstName",
    rules: [
      {
        required: true,
        message: "First name is required",
      },
    ],
    label: "First Name",
    placeholder: "First Name",
  },
  {
    name: "lastName",
    rules: [
      {
        required: true,
        message: "Last name is required",
      },
    ],
    label: "Last Name",
    placeholder: "Last Name",
  },
  {
    name: "email",
    rules: [
      {
        required: true,
        message: "Email address is required",
      },
    ],
    label: "Email Address",
    placeholder: "name@domain.com",
  },
  {
    name: "phoneNumber",
    rules: [
      {
        validator: async (_: any, value: any) => {
          if (!value || isValidPhoneNumber(value.replaceAll("-", ""))) {
            return Promise.resolve();
          }
          return Promise.reject(new Error("Phone number is invalid"));
        },
      },
      { required: false },
    ],
    label: "Phone Number",
  },
];
