import React from "react";
import Footer from "./Footer";
import Header from "./Header";

type Props = {
  children?: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  return (
    <div className="layout-wrapper">
      <Header />
      <div className="layout-wrapper--content">{children}</div>
      <Footer />
    </div>
  );
};

export const LayoutContentContainer = ({ children }: Props) => {
  return <div className="layout-content-container">{children}</div>;
};

export default Layout;
