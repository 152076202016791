import { useLocation } from "react-router-dom";
import { AxiosError } from "axios";

import { useState, Dispatch, SetStateAction } from "react";
import { ModalProps, Row, Col, Form, Button } from "antd";
import queryString from "query-string";

import Spacer from "components/Spacer";
import FauxModal from "./Modal";
import ConfirmationModal from "./ConfirmationModal";
import apiRequest from "utils/network";
import { isValidEmail } from "utils/validators";

import { ErrorBox } from "./ErrorBox";
import { getFormField } from "./getFormField";
import "./style.scss";

import { formFields } from "./formFields";

type RegistrationFormProps = ModalProps & {
  registrationModalIsOpen: boolean;
  setRegistrationFormVisible: Dispatch<SetStateAction<boolean>>;
  programType: "HB" | "CP";
};

const RegistrationForm = ({
  programType,
  registrationModalIsOpen,
  setRegistrationFormVisible,
  ...modalProps
}: RegistrationFormProps) => {
  const [form] = Form.useForm();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errors, setErrors] = useState("");

  let { search } = useLocation();
  const { isDemo = false, program_id } = queryString.parse(search);

  const closeRegistrationModal = () => {
    form.resetFields();
    setFormSubmitted(false);
    setRegistrationFormVisible(false);
  };

  const completionHandler = async () => {
    const values = form.getFieldsValue();
    if (!isValidEmail(values.email)) {
      form.setFields([
        {
          name: "email",
          errors: ["Email address is invalid"],
        },
      ]);
      return;
    }

    setFormSubmitted(true);
    setErrors("");
    const formValues = {
      ...values,
      ...(values["phoneNumber"] && {
        phoneNumber: `+1-${values["phoneNumber"].replaceAll("-", "")}`,
      }),
      is_demo: isDemo,
    };

    if (!!program_id) {
      formValues["program_id"] = program_id;
    }

    try {
      await apiRequest({
        method: "POST",
        endpoint: "/api/v1/partner-registration/",
        data: formValues,
      });

      closeRegistrationModal();
      setShowConfirmation(true);
    } catch (e) {
      const { response } = e as AxiosError;
      if (response?.data) {
        setErrors(response.data?.message);
      }
      setFormSubmitted(false);
    }
  };

  return (
    <>
      <ConfirmationModal
        destroyOnClose
        visible={showConfirmation}
        onCancel={() => {
          setShowConfirmation(false);
        }}
      />
      <FauxModal
        visible={registrationModalIsOpen}
        {...modalProps}
        onCancel={closeRegistrationModal}
      >
        <div className="registration-form--content">
          <Spacer height={24} type="flex" />
          <h3>Get Enrolled</h3>
          {!!errors && <ErrorBox>{errors}</ErrorBox>}
          <Spacer height={12} type="flex" />
          <Form
            form={form}
            layout={"vertical"}
            requiredMark={false}
            onFinish={completionHandler}
            scrollToFirstError={{ behavior: "smooth" }}
          >
            <Spacer height={12} type="flex" />
            <Row gutter={24}>
              {formFields.map(({ name, rules, label, placeholder }) => {
                return (
                  <Col key={name} md={12} xs={24}>
                    <Form.Item
                      name={name}
                      rules={rules}
                      label={
                        <span className="form-label-with-info">{label}</span>
                      }
                    >
                      {getFormField({ name, placeholder })}
                    </Form.Item>
                  </Col>
                );
              })}
              <Col md={24}>
                <Spacer height={6} type="flex" />
              </Col>
              <Col md={12} xs={24}>
                <Form.Item>
                  <Button
                    loading={formSubmitted}
                    className="registration-submit-button"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
              <Col md={12} xs={24} />
            </Row>
          </Form>
        </div>
      </FauxModal>
    </>
  );
};

export default RegistrationForm;
