import axios, { Method } from "axios";

type ApiRequestProps = {
  method?: Method;
  params?: any;
  data?: any;
  endpoint: string;
};

const apiRequest = ({
  method = "GET",
  params,
  data,
  endpoint,
}: ApiRequestProps) => {
  return axios.request({
    baseURL: "", // TOOD: check if we need empty value for that
    url: endpoint,
    params,
    data,
    method,
  });
};

export default apiRequest;
