import React from "react";
import "./style.scss";

export const ErrorBox: React.FC = ({ children }) => {
  if (!children) return null;

  return (
    <div className="registration-error-box">
      <span>{children}</span>
    </div>
  );
};
