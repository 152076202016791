import { useRef, Dispatch, SetStateAction } from "react";
import Spacer from "../Spacer";

import "./style.scss";

type BannerProps = {
  setRegistrationFormVisible: Dispatch<SetStateAction<boolean>>;
};

const Banner = ({ setRegistrationFormVisible }: BannerProps) => {
  return (
    <div className="banner-wrapper">
      <div className="banner-content">
        <h1>PatientLink</h1>
        <h2>Medication Manager and Health HUB</h2>
        <Spacer height={20} />
        <RegisterButton
          setRegistrationFormVisible={setRegistrationFormVisible}
        />
      </div>
      <div className="mask" />
    </div>
  );
};

const RegisterButton = ({ setRegistrationFormVisible }: BannerProps) => {
  const buttonRef = useRef(null);
  const registerButtonHandler = () => {
    setRegistrationFormVisible(true);
  };

  return (
    <button
      ref={buttonRef}
      className="hub-banner-cta-button"
      onClick={registerButtonHandler}
    >
      Register now
    </button>
  );
};

export default Banner;
