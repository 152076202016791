import { ReactComponent as Logo } from "assets/icons/patient-link.svg";

import "./style.scss";

const Header = () => {
  return (
    <header>
      <div className="header-content">
        <div className="logo-wrapper">
          <Logo />
        </div>
      </div>
    </header>
  );
};

export default Header;
