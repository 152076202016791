import { Button, ModalProps } from "antd";
import FauxModal from "../Modal";
import Spacer from "components/Spacer";
import "./style.scss";

const ConfirmationModal = (props: ModalProps) => {
  return (
    <FauxModal {...props} className="confirmation-modal">
      <div className="modal-content">
        <Spacer height={28} type="flex" />
        <h3>Thanks for enrolling in PatientLink</h3>
        <Spacer height={10} type="flex" />
        <p>What's next?</p>
        <ul>
          <li>You’ll receive an email shortly with access to your account.</li>
        </ul>
        <Spacer height={30} type="flex" />
        <Button className="confirmation-done-button" onClick={props.onCancel}>
          Done
        </Button>
        <Spacer height={50} type="flex" />
      </div>
    </FauxModal>
  );
};

export default ConfirmationModal;
