import { ReactComponent as Logo } from "assets/icons/patient-link-bottom.svg";

const Footer = () => {
  return (
    <footer>
      <div className="logo">
        <Logo />
      </div>
      <Copyright />
    </footer>
  );
};

const Copyright = () => {
  return (
    <p className="copyright-text">
      © 2022 PatientLink, a TrialCard Company. All Rights Reserved.
    </p>
  );
};

export default Footer;
